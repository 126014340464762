module.exports = {
  getImageClass,
  getStillImageUrl
};

function getImageClass(image) {
  if (!image) {
    return;
  }

  if (image.type === 'gif') {
    return 'adobe-fonts-family-image-card--gif';
  }
}

function getStillImageUrl(imagesData) {
  if (!imagesData) {
    return '';
  }

  for (let i = 0; i < imagesData.length; i++) {
    if (imagesData[i].type !== 'gif') {
      return imagesData[i].url_largest || imagesData[i].url;
    }
  }

  return '';
}
