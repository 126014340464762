var _ = require('underscore');
/**
 * @ngInject
 */
function FeedbackIconsDirectiveController($scope, FeedbackService, NewrelicService) {
  var self = this;
  self.FeedbackService = FeedbackService;
  self.NewrelicService = NewrelicService;

  $scope.$watch(function() {
    return self.FeedbackService.getSubmittedFeedback(self._getID(), self.familySlug());
  }, function(feedback) {
    self.selectedFeedback = feedback;
  });
}

/**
 * We display the thumbs up/down icons for situations where we want +/- feedback.
 * @return {Boolean}
 */
FeedbackIconsDirectiveController.prototype.areThumbsUpDownVisible = function() {
  return !this.isSimilarityAndPairingVariant();
};

/**
 * We display the cancel button when we want a single negative feedback option.
 * @return {Boolean}
 */
FeedbackIconsDirectiveController.prototype.isCancelButtonVisible = function() {
  return this.isSimilarityAndPairingVariant();
};

FeedbackIconsDirectiveController.prototype.isSimilarityAndPairingVariant = function() {
  return this._isFontSimilarityVariant() || this._isFontPairingVariant();
};

FeedbackIconsDirectiveController.prototype.initSelectedFeedback = function() {
  return _.isEmpty(this.selectedFeedback) ? 'positive' : 'negative';
};

FeedbackIconsDirectiveController.prototype.toggleSelectedFeedback = function() {
  return this.selectedFeedback === 'negative' ? 'positive' : 'negative';
};

/**
 * Toggle feedback icon class between selected and non-selected states
 * @param {String} feedback
 * @return {String} feedbackClass
 * @return {String}
 */
FeedbackIconsDirectiveController.prototype.getClass = function(feedback, feedbackClass) {
  return this.selectedFeedback === feedback ? [feedbackClass, 'on'].join(' ') : feedbackClass;
};

FeedbackIconsDirectiveController.prototype.getTooltipClass = function() {
  if (this.isCancelButtonVisible()) {
    return this.selectedFeedback === 'negative' ? 'spectrum-Tooltip--bottom flag-icon-tooltip on' : 'spectrum-Tooltip--bottom flag-icon-tooltip';
  }

  return 'spectrum-Tooltip--top';
};

/**
 * Handle click on a feedback icon
 * @param {Event} $event
 * @param {String} selectedFeedback
 */
FeedbackIconsDirectiveController.prototype.handleClick = function($event, selectedFeedback) {
  if ($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  // selectedFeedback will be set later when FeedbackService.post resolves,
  // but setting it here toggles the icon immediately.
  this.selectedFeedback = selectedFeedback;

  // Although a Transaction is created by the POST request below, a PageAction is still needed for tracking by session.
  if (this.isSimilarityAndPairingVariant()) {
    this.NewrelicService.addPageAction('typekit.browse.similarity_and_pairing.feedback', this._getNewRelicAttributes(selectedFeedback));
    this.FeedbackService.post(this.category(), this.subcategory(), this._getID(), selectedFeedback, this.familySlug(), this.variationOne().id, this.variationTwo().id);
  } else {
    this.NewrelicService.addPageAction('typekit.browse.click', this._getNewRelicAttributes(selectedFeedback));
    this.FeedbackService.post(this.category(), this.subcategory(), this._getID(), selectedFeedback, this.familySlug());
  }
};

/**
 * Return an identifier of the thing we're collecting feedback on
 * @return {String}
 */
FeedbackIconsDirectiveController.prototype._getID = function() {
  if (this._isTagVariant()) {
    return this.FeedbackService.filtersModel.getSelectedTag();
  }

  if (this._isRecommendationVariant()) {
    return this.user();
  }

  if (this.isSimilarityAndPairingVariant()) {
    return this.variationOne().id + '-' + this.variationTwo().id;
  }

  throw new Error('Unrecognized feedback icon category: ' + this.category());
};

FeedbackIconsDirectiveController.prototype._isFontPairingVariant = function() {
  return this.category() === 'pairing';
};

FeedbackIconsDirectiveController.prototype._isFontSimilarityVariant = function() {
  return this.category() === 'similarity';
};

FeedbackIconsDirectiveController.prototype._isRecommendationVariant = function() {
  return this.category() === 'recommendation';
};

FeedbackIconsDirectiveController.prototype._isTagVariant = function() {
  return this.category() === 'tag';
};

/**
 * Get attributes for New Relic Page Action
 * @param {String} selectedFeedback
 * @return {String}
 */
FeedbackIconsDirectiveController.prototype._getNewRelicAttributes = function(selectedFeedback) {
  if (this._isTagVariant()) {
    return {
      tagName: this.FeedbackService.filtersModel.getSelectedTag(),
      familySlug: this.familySlug(),
      feedback: selectedFeedback
    };
  }

  if (this._isRecommendationVariant()) {
    return {
      familySlug: this.familySlug(),
      feedback: selectedFeedback,
      subcategory: this.subcategory(),
      user: this.user()
    };
  }

  if (this.isSimilarityAndPairingVariant()) {
    return {
      category: this.category(),
      subcategory: this.subcategory(),
      feedback: selectedFeedback,
      variation_one: this.variationOne().name,
      variation_two: this.variationTwo().name
    };
  }

  throw new Error('Unrecognized feedback icon category: ' + this.category());
};

module.exports = FeedbackIconsDirectiveController;
