/**
 * A directive with "thumb up" and "thumb down" icons that submit feedback.
 *
 * @example
 *  <feedback-icons category="'tag'" family-slug="acumin"></feedback>
 *
 * @ngInject
 */
function Directive() {
  return {
    scope: {},
    bindToController: {
      category: '&',
      familySlug: '&',
      subcategory: '&',
      tooltipText: '&',
      user: '&',
      variationOne: '&',
      variationTwo: '&',
      selectedFeedback: '='
    },
    controller: require('./controller.js'),
    controllerAs: 'feedbackIcons',
    restrict: 'E',
    replace: true,
    templateUrl: '/angular_templates/directives/feedback_icons.html'
  };
}

module.exports = Directive;
